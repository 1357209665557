import * as React from 'react';

import { cn } from '@/lib/utils';

export type CommentInputProps = React.InputHTMLAttributes<HTMLInputElement>;

const CommentInput = React.forwardRef<HTMLInputElement, CommentInputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'flex h-full bg-transparent border-none ring-none outline-none px-3 py-2 text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
CommentInput.displayName = 'Input';

export { CommentInput };
