export default function Style() {
  return (
    <style>
      {`
         .auth-bg {
    height: 100vh;
    position: relative;
    clip-path: polygon(125px 0%, 1600px 0%, 1600px 100%, 0 100%);
  }
  
  @media screen and (max-width: 990px) {
    .auth-bg {
      display: none;
    }
    .logo1 {
      height: 50%;
      margin: 0 5% !important;
      cursor: pointer;
    }
  }
  

  .maintenance-container {
    background-color: white;
  }
    .rs-col {
      padding: 0 !important;
    }
  
    .rs-footer {
      text-align: center;
      font-size: 14px;
    }
  
  
  .logo1 {
    height: 50%;
    margin: 0 30px;
    cursor: pointer;
  }
  
  .maintenance-content {
    background-color: #fff;
    background-position: right;
    background-repeat: no-repeat;
    /* // height: 80vh; */
    background-position-y: bottom;
    background-position-x: RIGHT;
    background-size: 400px;
    margin-bottom: 20px;
  }
  
          `}
    </style>
  );
}
