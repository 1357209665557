import { GptHistoryResponse } from 'src/types/Services/GPT/gpt';

export const getTodaysQuestions = (data: GptHistoryResponse[]) => {
  const now = new Date();
  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

  // Filter the items created today and store them in todayItems
  const todayItems = data.filter(item => {
    const createdAt = new Date(item.createdAt);
    return createdAt >= startOfToday && createdAt <= endOfToday;
  });

  return todayItems;
};
