import styled from '@emotion/styled';
import { Schema, Form, FormControl, Panel, FormGroup, Radio, RadioGroup } from 'rsuite';
export const Model = Schema.Model({
  firstChoice: Schema.Types.StringType().isRequired('This field is required'),
  secondChoice: Schema.Types.StringType().isRequired('This field is required'),
  thirdChoice: Schema.Types.StringType().isRequired('This field is required'),
});

import { Grid, Row, Col } from 'rsuite';
const Step3Form = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors, shadow, space }) => {
  const FormTagline = styled('h4')`
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
  `;
  return (
    <div>
      {space ? (
        <FormTagline>
          Which is Your <span className="text-decoration-underline">First</span> choice?
        </FormTagline>
      ) : (
        <FormTagline className="mb-0">
          Which is Your <span className="text-decoration-underline">First</span> choice?
        </FormTagline>
      )}

      <Form
        checkTrigger="change"
        formValue={roomieProfileData}
        onCheck={setFormErrors}
        formError={formErrors}
        onChange={setroomieProfileData}
        model={Model}
        fluid>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    size="xs"
                    name="firstChoice"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, firstChoice: val })}>
                    <Row>
                      <Col md={12}>
                        <Radio value="Dinner Out" className="h6">
                          Dinner Out <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>A nice meal with friends</span>
                        </Radio>
                      </Col>
                      <Col md={12}>
                        <Radio value=" Go out to a bar" className="h6">
                          Go out to a bar <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>Grab drinks,maybe food too</span>
                        </Radio>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Radio value="Relax at home" className="h6">
                          Relax at home <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>
                            Netflix,board games, or anything else
                          </span>
                        </Radio>
                      </Col>
                      <Col md={12}>
                        <Radio value="Nightclub" className="h6">
                          Nightclub <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>Enjoying the city nightlife</span>
                        </Radio>
                      </Col>
                    </Row>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>

        <FormTagline className="mt-4 mb-0">
          <span className="text-decoration-underline">Second</span> choice?
        </FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    size="xs"
                    inline
                    name="secondChoice"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, secondChoice: val })}>
                    <Row>
                      <Col md={12}>
                        <Radio value="Dinner Out" className="h6">
                          Dinner Out <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>A nice meal with friends</span>
                        </Radio>
                      </Col>
                      <Col md={12}>
                        <Radio value="Go out to a bar" className="h6">
                          Go out to a bar <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>Grab drinks,maybe food too</span>
                        </Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Radio value="Relax at home" className="h6">
                          Relax at home <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>
                            Netflix,board games, or anything else
                          </span>
                        </Radio>
                      </Col>
                      <Col md={12}>
                        <Radio value="Nightclub" className="h6">
                          Nightclub <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>Enjoying the city nightlife</span>
                        </Radio>
                      </Col>
                    </Row>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>

        <FormTagline className="mt-4 mb-0">
          <span className="text-decoration-underline">Third</span> choice?
        </FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    size="xs"
                    inline
                    name="thirdChoice"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, thirdChoice: val })}>
                    <Row>
                      <Col md={12}>
                        <Radio value="Dinner Out" className="h6">
                          Dinner Out <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>A nice meal with friends</span>
                        </Radio>
                      </Col>
                      <Col md={12}>
                        <Radio value="Go out to a bar" className="h6">
                          Go out to a bar <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>Grab drinks,maybe food too</span>
                        </Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Radio value="Relax at home" className="h6">
                          Relax at home <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>
                            Netflix,board games, or anything else
                          </span>
                        </Radio>
                      </Col>
                      <Col md={12}>
                        <Radio value="Nightclub" className="h6">
                          Nightclub <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>Enjoying the city nightlife</span>
                        </Radio>
                      </Col>
                    </Row>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
      </Form>
    </div>
  );
};

export default Step3Form;
