import { LeniQContextType, LeniQContext } from 'contexts/LeniQContext';
import { useContext } from 'react';

const useLeniQContext = (): LeniQContextType => {
  const context = useContext(LeniQContext);
  if (!context) {
    throw new Error('useLeniQContext must be used within a DashboardContextProvider');
  }
  return context;
};

export default useLeniQContext;
