import {
  differenceInWeeks,
  previousSunday,
  endOfQuarter,
  endOfWeek,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subWeeks,
  differenceInDays,
  endOfDay,
} from 'date-fns';
import React, { createContext, useReducer, useEffect } from 'react';
import { dropDownOptions } from '../containers/DashboardMarketing/index.js';
import dataObject from '../containers/DashboardMarketing/leasingData.js';
import { UserApi } from '../services/api/user';
import { useUser } from './UserContext';
import insightsData from '../insights';
import { useErrorTracker } from 'utils/use-error-tracker.js';
import { Alert } from 'rsuite';

//Dashboard Context

let initialState = {
  DashboardTab: {
    buildingIds: new Set(),
    buildingCodes: new Set(),
    dateRange: [startOfQuarter(new Date()), endOfQuarter(new Date())],
    numberWeeks: differenceInWeeks(endOfQuarter(new Date()), startOfQuarter(new Date())),
    lastWeek: new Date('22-Aug-22'),
    cgiTestWeek: previousSunday(new Date()),
    filter: 'leasing',
    module: 'analytics',
    portfolio: '',
    isCardOpen: true,
    users: [], // list of all users in the organisation
    userSelected: '', // user selected in the dropdown
    bedrooms: [], // bedroom selection for pricing dashboard
    neighborhood: [], //neighborhood selection for pricing dashboard
    city: [], //city selection for pricing dashboard
    building: [], //building selection for pricing dashboard
    propertyGroup: {}, // multiple building ids under one group property name
  },
};

export const DashboardContext = createContext(initialState);

export const ContextProvider = ({ children }) => {
  const getUser = useUser();
  // console.log({ getUser });

  //Further review needed to determine why this is triggering after logout setting to reporting unless an else statement is added
  //Other filters seem to update as expected
  if (getUser?.user?.organisationId === '855a85f6-ebcc-4af5-a443-c7058ed56e12') {
    //Special case for CGI, TODO should be refactored to be dynamic
    initialState.DashboardTab = {
      ...initialState.DashboardTab,
      filter: getUser?.user?.dashboardViews[0],
      dateRange: [startOfWeek(subWeeks(new Date(), 1)), endOfDay(startOfWeek(new Date()))],
    };
  } else if (getUser?.user?.views?.includes('price') && getUser?.user?.views?.includes('price+')) {
    getUser?.user?.organisationId === '62250b17-54eb-4329-8826-3f6985111e86' //HUDSON ID IN PRODUCTION
      ? (initialState.DashboardTab = {
          ...initialState.DashboardTab,
          filter: 'standard_occupancy_and_leasing_performance',
        })
      : (initialState.DashboardTab = {
          ...initialState.DashboardTab,
          filter: 'reporting',
          dateRange: [startOfYear(new Date()), new Date()],
          // dateRange: [subQuarters(startOfQuarter(new Date()), 1), subQuarters(endOfQuarter(new Date()), 1)],
        });
  } else if (getUser?.user?.dashboardViews?.includes('leasing')) {
    initialState.DashboardTab = { ...initialState.DashboardTab, filter: 'leasing' };
  } else {
    initialState.DashboardTab = { ...initialState.DashboardTab, filter: getUser?.user?.dashboardViews[0] };
  }

  const errorTracker = useErrorTracker();

  useEffect(() => {
    const getUsersData = async () => {
      try {
        const results = await UserApi.getUsersManaged().catch(err => {
          errorTracker.error('Error when getting users managed', { currentUserId: getUser?.user?.id });
          Alert.error('Something went wrong while fetching users data in dashboard.', 5000);
          console.error(err);
        });

        dashboardDispatch({
          type: 'setUsers',
          payload: results.data,
        });
      } catch (err) {
        console.log({ err });
        Alert.error('Something went wrong while fetching users in dashboard');
      }
    };
    if (insightsData.validUsers.includes(getUser?.user?.email)) {
      dashboardDispatch({
        type: 'setDateRange',
        payload: [new Date('01-Jul-22'), new Date('22-Aug-22')],
      });
      dashboardDispatch({
        type: 'setFilter',
        payload: 'management',
      });
    }
    // only fetch users data if organisationId is set
    // if the organisationId is not set, it is guaranteed that no users will be returned
    // hence, there is no need to fetch users data
    if (getUser?.user?.organisationId) {
      getUsersData();
    }
  }, [getUser?.user?.email, getUser?.user?.organisationId, errorTracker, getUser?.user?.id]);

  const [dashboardState, dashboardDispatch] = useReducer((dashboardState, action) => {
    switch (action.type) {
      case 'setBuilding':
        // console.log('setBuilding', new Set(action.payload));
        // convert buildingIds from array to set
        action.payload = new Set(action.payload);
        // the following double for loop is only for demo purposes
        // If portfolio has not been set, find and set the portfolio corresponding to building value
        if (insightsData.validUsers.includes(getUser?.user?.email) && !dashboardState.DashboardTab.portfolio) {
          for (const portfolioName of dropDownOptions(dataObject).portfolios) {
            for (const buildingName of dropDownOptions(dataObject)[portfolioName]) {
              if (action.payload.has(buildingName)) {
                return {
                  ...dashboardState,
                  DashboardTab: {
                    ...dashboardState?.DashboardTab,
                    buildingIds: action.payload,
                    portfolio: portfolioName,
                  },
                };
              }
            }
          }
        }
        // Otherwise, set building value only
        // console.log('action.payload', action.payload);
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, buildingIds: action.payload },
        };

      case 'setPropertyGroup':
        // console.log('building group', action.payload);
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, propertyGroup: action.payload },
        };
      case 'setBuildingCode':
        // convert buildingCodes from array to set
        action.payload = new Set(action.payload);
        // console.log('action.payload', action.payload);
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, buildingCodes: action.payload },
        };

      case 'setDateRange':
        if (action.payload && action.payload.length && insightsData.validUsers.includes(getUser?.user?.email)) {
          let [startDate, endDate] = action.payload;
          // Only modify endDate to before 22-Aug-22 if startDate is also less than 22-Aug-22
          // Otherwise, startDate > endDate which will throw an error
          // if (endDate > new Date('22-Aug-22') && startDate < new Date('22-Aug-22')) {
          //   endDate = new Date('22-Aug-22');
          // }

          // Ensure that weeks between start and end date can never be 0 because this will throw an error
          const weeksBetween = (d1, d2) => {
            return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
          };

          if (weeksBetween(startDate, endDate) === 0) {
            endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 7);
          }

          action.payload = [startDate, endDate];
        }
        // If the filter is one of the following, change the date range to the start and end of the week
        if (
          (dashboardState.DashboardTab?.filter === 'cgi_vp_exec' ||
            dashboardState.DashboardTab?.filter === 'cgi_leasing' ||
            dashboardState.DashboardTab?.filter === 'cgi_vp_exec_sandbox' ||
            dashboardState.DashboardTab?.filter === 'cgi_operations') &&
          action.payload.length &&
          differenceInDays(action.payload[1], action.payload[0]) < 14
        ) {
          const oldStart = action.payload[0];
          const oldEnd = action.payload[1];
          const newStart = startOfWeek(oldStart);
          const newEnd = endOfWeek(oldEnd);
          action.payload = [newStart, newEnd];
        }

        return {
          ...dashboardState,
          DashboardTab: {
            ...dashboardState?.DashboardTab,
            dateRange: action.payload,
            numberWeeks:
              action.payload && action.payload.length ? differenceInWeeks(action.payload[1], action.payload[0]) : 3,
            lastWeek: action.payload && action.payload.length ? new Date(action.payload[1]) : new Date('22-Aug-22'),
          },
        };
      case 'setDate': {
        // CGI Specific, change later for other use cases
        let endDate = new Date(action.payload);
        let startDate = new Date(endDate.getTime() - 14 * 24 * 60 * 60 * 1000); // Take the last two weeks to get 3 weeks of data
        return {
          ...dashboardState,
          DashboardTab: {
            ...dashboardState?.DashboardTab,
            dateRange: [startDate, endDate],
          },
        };
      }
      case 'setFilter':
        return { ...dashboardState, DashboardTab: { ...dashboardState?.DashboardTab, filter: action.payload } };
      case 'setModule':
        return { ...dashboardState, DashboardTab: { ...dashboardState?.DashboardTab, module: action.payload } };
      case 'setPortfolio':
        // whenever portfolio value is changed, reset building value
        // this is because flow should always be set portfolio THEN set building. In other words, there's no reason for buildling value to be set when portfolio value changes
        // If resetting portfolio value, reset building value too
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, portfolio: action.payload, buildingId: '' },
        };
      // if (!action.payload) {
      // } else {
      //   // Otherwise, set portfolio value only
      //   return { ...dashboardState, DashboardTab: { ...dashboardState?.DashboardTab, portfolio: action.payload } };
      // }
      case 'setYear':
        return { ...dashboardState, DashboardTab: { ...dashboardState?.DashboardTab, year: action.payload } };
      case 'setGraphRange':
        return { ...dashboardState, DashboardTab: { ...dashboardState?.DashboardTab, graphRange: action.payload } };
      case 'toggleCardOpen':
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, isCardOpen: !dashboardState.DashboardTab.isCardOpen },
        };
      case 'setUsers':
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, users: action.payload },
        };
      case 'setUserSelected':
        return {
          ...dashboardState,
          DashboardTab: {
            ...dashboardState?.DashboardTab,
            userSelected: action.payload,
            // do not set buildingIds for demo accounts
            buildingIds: insightsData.validUsers.includes(getUser?.user?.email)
              ? new Set()
              : action.payload
              ? new Set(dashboardState?.DashboardTab.users.filter(user => user?.id === action.payload)[0].buildingIds)
              : new Set(), // find the user selected > get the buildingIds from that user > convert the list of buildingIds into a set
          },
        };
      case 'setBedrooms':
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, bedrooms: action.payload },
        };
      case 'setNeighborhood':
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, neighborhood: action.payload },
        };
      case 'setCity':
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, city: action.payload },
        };
      case 'setLastDisplayedDashboardId':
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, lastDisplayedDashboardId: action.payload },
        };
      case 'setLastUrlParamsUsed':
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, lastUrlParamsUsed: action.payload },
        };
      case 'setPriceBuilding':
        return {
          ...dashboardState,
          DashboardTab: { ...dashboardState?.DashboardTab, building: action.payload },
        };
      default:
        return dashboardState;
    }
  }, initialState);

  return (
    <DashboardContext.Provider
      value={{
        dashboardState,
        dashboardDispatch,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};
