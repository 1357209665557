import styled from '@emotion/styled';
import Step5Form from './StepsForms/Step5Form';
const Step5 = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors }) => {
  const FormTitle = styled('h3')`
    font-size: 24px;
    font-weight: 600;
  `;

  return (
    <>
      <FormTitle>Cooking at home</FormTitle>
      <Step5Form
        space={true}
        roomieProfileData={roomieProfileData}
        setroomieProfileData={setroomieProfileData}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </>
  );
};

export default Step5;
