import { Insight } from 'data/insightData';
import React, { useEffect, useMemo, useState } from 'react';
import useInsightContext from 'utils/hooks/useInsightContext';

const InsightBody: React.FC = () => {
  const [insight, setInsight] = useState<Insight | null>(null);
  const {
    state: { activeInsight, allInsights },
  } = useInsightContext();

  const memoizedActiveInsight = useMemo(() => activeInsight, [activeInsight]);
  const memoizedAllInsights = useMemo(() => allInsights, [allInsights]);

  useEffect(() => {
    if (memoizedActiveInsight) {
      const foundInsight = memoizedAllInsights.find(insight => insight.id === memoizedActiveInsight.id);
      if (foundInsight) {
        setInsight(foundInsight);
      }
    }
  }, [memoizedActiveInsight, memoizedAllInsights]);

  return (
    <div>
      <div className={`flex items-center justify-between mb-3`}>
        <h4 className="text-lg font-bold">{insight?.insightName}</h4>
        {insight?.monitoring && (
          <span className="text-xs text-success-500 bg-success-50 px-2 py-1 rounded-xl">Monitoring</span>
        )}
      </div>
      {insight?.note && <span className="bg-primary-50 text-primary-500 p-2 rounded-full">{insight?.note}</span>}
      <div className="mt-4">{insight?.insightText}</div>
    </div>
  );
};

export default InsightBody;
