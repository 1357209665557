import { useEffect, useState } from 'react';
import { BookingApi } from 'services/api/booking';
import { Router } from 'utils/with-i18next';
import { Alert } from 'rsuite';
import { useErrorTracker } from '../use-error-tracker';

const restrictedPages = new Set(['dashboard', 'properties', 'bookings', 'tickets', 'income-management', 'price']);

export function useUserAuthorization(user, pageId) {
  const errorTracker = useErrorTracker();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user?.status) {
      setIsAuthorized(false);
      return;
    }

    if (user?.userType === 'landlord') {
      if (restrictedPages.has(pageId) && user.views && !user.views.includes(pageId)) {
        Router.push(`/403`);
      } else {
        setIsAuthorized(true);
      }
      return;
    }

    if (user?.userType === 'renter') {
      BookingApi.fetchBookings('tenant')
        .then(res => {
          if (Array.isArray(res.data) && res.data.length > 0) {
            const userBooking = res.data.filter(
              item =>
                item.status === 'paid' ||
                item.status === 'approved' ||
                item.status === 'awaiting_application' ||
                item.status === 'documents-uploaded'
            );
            if (userBooking.length > 0) {
              Router.push(`/my-bookings/${userBooking[0].id}`);
            } else {
              Router.push('/properties');
            }
          } else {
            Router.push('/properties');
          }
        })
        .catch(err => {
          errorTracker.error('Fetch bookings error', { fetchBookingsParam: 'tenant' });
          Alert.error('Something went wrong while fetching bookings.', 5000);
          console.error(err);
        });
    }
  }, [user, pageId, errorTracker]);

  return isAuthorized;
}
