import { Tooltip, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { TooltipContent } from '@radix-ui/react-tooltip';
import Image from 'next/image';
import React from 'react';
import useLeniQContext from 'utils/hooks/useLeniQContext';

const LeniQEntry: React.FC = () => {
  const {
    state: { leniQDrawerOpen },
    dispatch: leniQDispatch,
  } = useLeniQContext();

  const handleOpenLeniQ = () => {
    leniQDispatch({
      type: 'SET_LENIQ_DRAWER_OPEN',
      payload: !leniQDrawerOpen,
    });
  };

  return (
    <TooltipProvider delayDuration={250}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex justify-center items-center cursor-pointer" onClick={handleOpenLeniQ}>
            <div className="relative w-10 h-10 rounded-full shadow-lg bg-gradient-to-t from-sage-plus-start to-sage-plus-end transition-all hover:rotate-180 hover:shadow-sage-plus duration-500">
              <div className="absolute inset-0 flex justify-center items-center">
                <Image src="/static/icons/leniq-circle.svg" alt="LeniQ Circle" width={25} height={25} />
              </div>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          align="end"
          sideOffset={8}
          className="z-[1000] bg-white-50 p-3 border-[1px] border-gray-50 shadow-xl rounded-lg">
          <p className="text-gray-400">Ask LeniQ</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default LeniQEntry;
