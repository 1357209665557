import { useRef, useEffect, useState } from 'react';
import Image from 'next/image.js';
import Styles from './styles.js';
import detectWindowScreenSize from '../../utils/detect-windows-size';
import LoginForm from '../LoginForm';
import ForgetPasswordForm from '../ForgetPasswordForm';
import SignupForm from '../SignupForm';
import { useUser } from 'contexts/UserContext.tsx';

export default function LoginPopup({ setIsOpen, content }) {
  const popupRef = useRef();
  const [width] = detectWindowScreenSize();
  const [formAction, setFormAction] = useState('login');
  // const [redirectTo, setRedirectTo] = useState('/');
  const redirectIfFound = true;

  const { mutateUser } = useUser({
    ...{ redirectIfFound },
  });

  useEffect(() => {
    document.addEventListener('mousedown', event => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        document.getElementById('app-root').style.filter = 'blur(0px)';
        setIsOpen(false);
      }
    });
    return () => window.removeEventListener('mousedown', event);
  }, [setIsOpen]);

  return (
    <div className="popup-main">
      <div ref={popupRef} style={{ width: width <= '750' ? '50%' : '514px' }} className="popup-box">
        <div>
          <Image className="logo" src="/static/images/BigLogo.png" alt="Leni Logo" layout="fill" />
        </div>
        <div className="popup-login-form">
          {/* Specific component is rendered based on formAction state value */}
          {formAction === 'login' && <LoginForm mutateUser={mutateUser} isPopup={true} setformAction={setFormAction} />}
          {formAction === 'forgot-password' && (
            <ForgetPasswordForm mutateUser={mutateUser} isPopup={true} setformAction={setFormAction} />
          )}
          {formAction === 'signup' && (
            <SignupForm mutateUser={mutateUser} isPopup={true} setformAction={setFormAction} userType={'renter'} />
          )}
        </div>

        {content}
      </div>
      <Styles />
    </div>
  );
}
