import styled from '@emotion/styled';
import Step2Form from './StepsForms/Step2Form';

const Step2 = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors }) => {
  const FormTitle = styled('h3')`
    font-size: 24px;
    font-weight: 600;
  `;

  return (
    <>
      <FormTitle> Your schedule</FormTitle>

      <Step2Form
        customSize
        space={true}
        roomieProfileData={roomieProfileData}
        setroomieProfileData={setroomieProfileData}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </>
  );
};

export default Step2;
