import React from 'react';
export default function Style() {
  return (
    <style>
      {`
        @media (max-width: 400px) {
     .cimDEH{
         width: 300px !important;
     }
     
 }
     
        `}
    </style>
  );
}
