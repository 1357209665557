import moment from 'moment';

export function getDateAndTime(dateString: string): { formattedDate: string; formattedTime: string } {
  const date = moment(dateString);

  const formattedDate = date.format('MMM Do, YYYY');
  const formattedTime = date.utc().format('h:mmA');

  return {
    formattedDate,
    formattedTime,
  };
}
