import React from 'react';
import Link from 'next/link';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { FileText, Plug } from 'lucide-react';

export default function SideNavFooter() {
  return (
    <TooltipProvider>
      <div className="w-full flex flex-col items-center py-6">
        <div className="flex flex-col justify-evenly items-center gap-6">
          <Tooltip>
            <Link href="/dashboard/integrations" passHref>
              <TooltipTrigger asChild>
                <span className="cursor-pointer">{<Plug height={26} width={26} />}</span>
              </TooltipTrigger>
            </Link>
            <TooltipContent side="right" className="bg-primary-800 border-none text-white-50">
              Integrations
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <Link
              href="https://realsage.notion.site/960a0c48ab48455f8e024d324dceaf88?v=0562f53322224c55beda0018a621aff0"
              passHref>
              <TooltipTrigger asChild>
                <a
                  target="_blank"
                  className="text-gray-200 hover:text-gray-200 active:text-gray-200 focus:text-gray-200 hover:no-underline focus:no-underline">
                  <div className="flex cursor-pointer gap-1 font-bold items-center">
                    <FileText height={26} width={26} />
                  </div>
                </a>
              </TooltipTrigger>
            </Link>
            <TooltipContent side="right" className="bg-primary-800 border-none text-white-50">
              <p>Guides</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
    </TooltipProvider>
  );
}
