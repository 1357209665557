import { useEffect, useState } from 'react';
import { Panel, FormGroup, Radio, Schema, FormControl, RadioGroup, Form } from 'rsuite';
import styled from '@emotion/styled';

import { Grid, Row, Col } from 'rsuite';
import CheckboxComponent from 'components/Checkbox';
import { compact, uniq } from 'lodash';

export const Model = Schema.Model({
  yourGender: Schema.Types.StringType().isRequired('This field is required'),
  allGenders: Schema.Types.StringType().isRequired('This field is required'),
  yourInterests: Schema.Types.ArrayType().minLength(1, 'At least one required'),
  extrovertedYouAre: Schema.Types.StringType().isRequired('This field is required'),
});
const Step9Form = ({
  roomieProfileData,
  setroomieProfileData,
  formErrors,
  setFormErrors,
  shadow,
  chkBoxShadow,
  space,
}) => {
  const FormTagline = styled('h4')`
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
  `;
  const [options, setOptions] = useState({
    yourInterestOptions: [],
  });

  const addCustomOption = (type, newOption) => {
    return setOptions({ ...options, [type]: [...options[type], newOption] });
  };

  useEffect(() => {
    setOptions({
      yourInterestOptions: uniq([
        'Netflix',
        'Movies',
        'Concerts',
        'Hiking',
        'Reading',
        'Video games',
        'Playing sports',
        'Watching sports',
        'Travelling',
        'Politics',
        'Technology',
        'Philosophy',
        'History',
        'Fashion',
        'Yoga',
        'Working out',
        'Volunteering',
        'Playing music',
        'Cooking',
        'Camping',
        'Baking',
        // ...getExtraData('favouritFoodsOptions'),
      ]),
    });
  }, []);
  return (
    <div>
      {space ? (
        <FormTagline>Do you have a preference?</FormTagline>
      ) : (
        <FormTagline className="mb-0">Do you have a preference?</FormTagline>
      )}

      <Form
        checkTrigger="change"
        formValue={roomieProfileData}
        onCheck={setFormErrors}
        formError={formErrors}
        onChange={setroomieProfileData}
        model={Model}
        fluid>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <FormTagline className="mb-2">Your gender?</FormTagline>
              <Col md={24}>
                <FormGroup controlId="radioList" className="mb-2">
                  <FormControl
                    inline
                    name="yourGender"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, yourGender: val })}>
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                    <Radio value="Non-Binary">Non-Binary</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormTagline className="mb-2">Which you&apos;d prefer to live with?</FormTagline>
                  <FormControl
                    inline
                    name="allGenders"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, allGenders: val })}>
                    <Radio value="All Male">All Male</Radio>
                    <Radio value="All Female">All Female</Radio>
                    <Radio value="No preference">No preference</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-3">What are your interests / hobbies / passions?</FormTagline>
        <CheckboxComponent
          shadow={chkBoxShadow ? true : false}
          name="yourInterests"
          lable="Your Interests"
          onChange={val => setroomieProfileData({ ...roomieProfileData, yourInterests: val })}
          options={compact(options.yourInterestOptions)}
          addCustomOption={newOption => addCustomOption('yourInterestOptions', newOption)}
        />

        <FormTagline className="mt-4 mb-0">From 1-5, how extroverted are you?</FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="extrovertedYouAre"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, extrovertedYouAre: val })}>
                    <Radio value="Introvert">
                      1 <span>Introvert</span>
                    </Radio>
                    <Radio value="Slight Introvert">
                      2 <span>Slight Introvert</span>
                    </Radio>
                    <Radio value="Ambivert">
                      3 <span>Ambivert</span>
                    </Radio>
                    <Radio value="Slight Extrovert">
                      4 <span>Slight Extrovert</span>
                    </Radio>
                    <Radio value="Extrovert">
                      5 <span>Extrovert</span>
                    </Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
      </Form>
    </div>
  );
};

export default Step9Form;
