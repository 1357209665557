import { TableauDashboardContext } from 'contexts/TableauDashboardContext';
import { useContext } from 'react';

const useTableauDashboardContext = () => {
  const context = useContext(TableauDashboardContext);
  if (!context) {
    throw new Error('useInsightContext must be used within a DashboardContextProvider');
  }
  return context;
};

export default useTableauDashboardContext;
