import Image from 'next/image';
import React from 'react';

interface LeniQQuestionProps {
  question: string;
  onClick: () => void;
}

const LeniQQuestion: React.FC<LeniQQuestionProps> = ({ question, onClick }) => {
  return (
    <div
      className="w-full p-2 flex items-center gap-2 rounded-full border-[1px] border-primary-200 cursor-pointer hover:shadow-md transition-all"
      onClick={onClick}>
      <Image
        src="/static/icons/leniq-circle.svg"
        height={25}
        width={25}
        className="animate-spin-slowest"
        alt="Loading spinner"
      />
      <span className="text-primary-700">{question}</span>
    </div>
  );
};

export default LeniQQuestion;
