import { InsightContextType, InsightContext } from 'contexts/InsightsContext';
import { useContext } from 'react';

const useInsightContext = (): InsightContextType => {
  const context = useContext(InsightContext);
  if (!context) {
    throw new Error('useInsightContext must be used within a DashboardContextProvider');
  }
  return context;
};

export default useInsightContext;
