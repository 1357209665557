import html2canvas from 'html2canvas';

export const getScreenCapture = async () => {
  const html = document.querySelector('.rs-content.db-content') as HTMLElement;
  if (html) {
    const canvas = await html2canvas(html);
    const base64Image = canvas.toDataURL('image/png');
    return base64Image;
  }
  return null;
};
