import { GptHistoryResponse } from 'src/types/Services/GPT/gpt';

export const getQuestionsBeforeYesterday = (data: GptHistoryResponse[]) => {
  const now = new Date();
  const startOfYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0);

  // Filter the items created before yesterday and exclude today and yesterday
  const itemsBeforeYesterday = data.filter(item => {
    const createdAt = new Date(item.createdAt);
    return createdAt < startOfYesterday;
  });

  return itemsBeforeYesterday;
};
