import seedrandom from 'seedrandom';

export const avatarStyleOptions = [
  { label: 'Circle', value: 'Circle' },
  { label: 'Transparent', value: 'Transparent' },
];

export const topTypeOptions = [
  { label: 'No Hair', value: 'NoHair' },
  { label: 'Eyepatch', value: 'Eyepatch' },
  { label: 'Hat', value: 'Hat' },
  { label: 'Hijab', value: 'Hijab' },
  { label: 'Turban', value: 'Turban' },
  { label: 'Winter Hat 1', value: 'WinterHat1' },
  { label: 'Winter Hat 2', value: 'WinterHat2' },
  { label: 'Winter Hat 3', value: 'WinterHat3' },
  { label: 'Winter Hat 4', value: 'WinterHat4' },
  { label: 'Long Hair Big Hair', value: 'LongHairBigHair' },
  { label: 'Long Hair Bob', value: 'LongHairBob' },
  { label: 'Long Hair Bun', value: 'LongHairBun' },
  { label: 'Long Hair Curly', value: 'LongHairCurly' },
  { label: 'Long Hair Curvy', value: 'LongHairCurvy' },
  { label: 'Long Hair Dreads', value: 'LongHairDreads' },
  { label: 'Long Hair Frida', value: 'LongHairFrida' },
  { label: 'Long Hair Fro', value: 'LongHairFro' },
  { label: 'Long Hair Fro Band', value: 'LongHairFroBand' },
  { label: 'Long Hair Not Too Long', value: 'LongHairNotTooLong' },
  { label: 'Long Hair Shaved Sides', value: 'LongHairShavedSides' },
  { label: 'Long Hair Mia Wallace', value: 'LongHairMiaWallace' },
  { label: 'Long Hair Straight', value: 'LongHairStraight' },
  { label: 'Long Hair Straight 2', value: 'LongHairStraight2' },
  { label: 'Long Hair Straight Strand', value: 'LongHairStraightStrand' },
  { label: 'Short Hair Dreads 01', value: 'ShortHairDreads01' },
  { label: 'Short Hair Dreads 02', value: 'ShortHairDreads02' },
  { label: 'Short Hair Frizzle', value: 'ShortHairFrizzle' },
  { label: 'Short Hair Shaggy Mullet', value: 'ShortHairShaggyMullet' },
  { label: 'Short Hair Short Curly', value: 'ShortHairShortCurly' },
  { label: 'Short Hair Short Flat', value: 'ShortHairShortFlat' },
  { label: 'Short Hair Short Round', value: 'ShortHairShortRound' },
  { label: 'Short Hair Short Waved', value: 'ShortHairShortWaved' },
  { label: 'Short Hair Sides', value: 'ShortHairSides' },
  { label: 'Short Hair The Caeser', value: 'ShortHairTheCaesar' },
  { label: 'Short Hair The Caeser Side Part', value: 'ShortHairTheCaesarSidePart' },
];

export const accessoriesTypeOptions = [
  { label: 'Blank', value: 'Blank' },
  { label: 'Kurt', value: 'Kurt' },
  { label: 'Prescription 01', value: 'Prescription01' },
  { label: 'Prescription 02', value: 'Prescription02' },
  { label: 'Round', value: 'Round' },
  { label: 'Sunglasses', value: 'Sunglasses' },
  { label: 'Wayfarers', value: 'Wayfarers' },
];

export const hairColorOptions = [
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Black', value: 'Black' },
  { label: 'Blonde', value: 'Blonde' },
  { label: 'Blonde Golden', value: 'BlondeGolden' },
  { label: 'Brown', value: 'Brown' },
  { label: 'Brown Dark', value: 'BrownDark' },
  { label: 'Pastel Pink', value: 'PastelPink' },
  { label: 'Platinum', value: 'Platinum' },
  { label: 'Red', value: 'Red' },
  { label: 'Silver Gray', value: 'SilverGray' },
];

export const facialHairTypeOptions = [
  { label: 'Blank', value: 'Blank' },
  { label: 'Beard Medium', value: 'BeardMedium' },
  { label: 'Beard Light', value: 'BeardLight' },
  { label: 'Beard Magestic', value: 'BeardMagestic' },
  { label: 'Moustache Fancy', value: 'MoustacheFancy' },
  { label: 'Moustache Magnum', value: 'MoustacheMagnum' },
];

export const facialHairColorOptions = [
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Black', value: 'Black' },
  { label: 'Blonde', value: 'Blonde' },
  { label: 'Blonde Golden', value: 'BlondeGolden' },
  { label: 'Brown', value: 'Brown' },
  { label: 'Brown Dark', value: 'BrownDark' },
  { label: 'Pastel Pink', value: 'PastelPink' },
  { label: 'Platinum', value: 'Platinum' },
  { label: 'Red', value: 'Red' },
  { label: 'Silver Gray', value: 'SilverGray' },
];

export const clotheTypeOptions = [
  { label: 'Blazer Shirt', value: 'BlazerShirt' },
  { label: 'Blazer Sweater', value: 'BlazerSweater' },
  { label: 'Collar Sweater', value: 'CollarSweater' },
  { label: 'Graphic Shirt', value: 'GraphicShirt' },
  { label: 'Hoodie', value: 'Hoodie' },
  { label: 'Overall', value: 'Overall' },
  { label: 'Shirt Crew Neck', value: 'ShirtCrewNeck' },
  { label: 'Shirt Scoop Neck', value: 'ShirtScoopNeck' },
  { label: 'Shirt V Neck', value: 'ShirtVNeck' },
];

export const clotheColorOptions = [
  { label: 'Black', value: 'Black' },
  { label: 'Blue 01', value: 'Blue01' },
  { label: 'Blue 02', value: 'Blue02' },
  { label: 'Blue 03', value: 'Blue03' },
  { label: 'Gray 01', value: 'Gray01' },
  { label: 'Gray 02', value: 'Gray02' },
  { label: 'Heather', value: 'Heather' },
  { label: 'Pastel Blue', value: 'PastelBlue' },
  { label: 'Pastel Orange', value: 'PastelOrange' },
  { label: 'Pastel Red', value: 'PastelRed' },
  { label: 'Pastel Yellow', value: 'PastelYellow' },
  { label: 'Pink', value: 'Pink' },
  { label: 'Red', value: 'Red' },
  { label: 'White', value: 'White' },
];

export const eyeTypeOptions = [
  { label: 'Close', value: 'Close' },
  // { label: 'Cry', value: 'Cry' },
  { label: 'Default', value: 'Default' },
  // { label: 'Dizzy', value: 'Dizzy' },
  { label: 'Eye Roll', value: 'EyeRoll' },
  { label: 'Happy', value: 'Happy' },
  { label: 'Side', value: 'Side' },
  { label: 'Squint', value: 'Squint' },
  { label: 'Surprised', value: 'Surprised' },
  { label: 'Wink', value: 'Wink' },
  { label: 'Wink Wacky', value: 'WinkWacky' },
];

export const eyebrowTypeOptions = [
  // { label: 'Angry', value: 'Angry' },
  // { label: 'Angry Natural', value: 'AngryNatural' },
  { label: 'Default', value: 'Default' },
  { label: 'Default Natural', value: 'DefaultNatural' },
  { label: 'Flat Natural', value: 'FlatNatural' },
  { label: 'Raised Excited', value: 'RaisedExcited' },
  { label: 'Raised Excited Natural', value: 'RaisedExcitedNatural' },
  // { label: 'Sad Concerned', value: 'SadConcerned' },
  // { label: 'Sad Concerned Natural', value: 'SadConcernedNatural' },
  { label: 'Unibrow Natural', value: 'UnibrowNatural' },
  { label: 'Up Down', value: 'UpDown' },
  { label: 'Up Down Natural', value: 'UpDownNatural' },
];

export const mouthTypeOptions = [
  // { label: 'Concerned', value: 'Concerned' },
  { label: 'Default', value: 'Default' },
  // { label: 'Disbelief', value: 'Disbelief' },
  { label: 'Eating', value: 'Eating' },
  // { label: 'Grimace', value: 'Grimace' },
  // { label: 'Sad', value: 'Sad' },
  // { label: 'Scream Open', value: 'ScreamOpen' },
  { label: 'Serious', value: 'Serious' },
  { label: 'Smile', value: 'Smile' },
  { label: 'Tongue', value: 'Tongue' },
  { label: 'Twinkle', value: 'Twinkle' },
  // { label: 'Vomit', value: 'Vomit' },
];

export const skinColorOptions = [
  { label: 'Yellow', value: 'Yellow' },
  { label: 'Pale', value: 'Pale' },
  { label: 'Light', value: 'Light' },
  { label: 'Brown', value: 'Brown' },
  { label: 'DarkBrown', value: 'DarkBrown' },
  { label: 'Black', value: 'Black' },
];

// not being used by user-generated avatars; only used by the avatar generator
export const hatColorOptions = [
  { label: 'Black', value: 'Black' },
  { label: 'Blue 01', value: 'Blue01' },
  { label: 'Blue 02', value: 'Blue02' },
  { label: 'Blue 03', value: 'Blue03' },
  { label: 'Gray 01', value: 'Gray01' },
  { label: 'Gray 02', value: 'Gray02' },
  { label: 'Heather', value: 'Heather' },
  { label: 'Pastel Blue', value: 'PastelBlue' },
  { label: 'Pastel Orange', value: 'PastelOrange' },
  { label: 'Pastel Red', value: 'PastelRed' },
  { label: 'Pastel Yellow', value: 'PastelYellow' },
  { label: 'White', value: 'White' },
];

// this function is used solely for creating an avatar that works with the rsuite Avatar component
export const getSourceUrl = avatarObject => {
  return `
      https://avataaars.io/?avatarStyle=${avatarObject.avatarStyle}&topType=${avatarObject.topType}&accessoriesType=${avatarObject.accessoriesType}&hairColor=${avatarObject.hairColor}&facialHairType=${avatarObject.facialHairType}&clotheType=${avatarObject.clotheType}&clotheColor=${avatarObject.clotheColor}&eyeType=${avatarObject.eyeType}&eyebrowType=${avatarObject.eyebrowType}&mouthType=${avatarObject.mouthType}&skinColor=${avatarObject.skinColor}`;
};

// a separate set of options have been created for avatar generator
// this is used for backwards compatibility as for some reason, specific options have been commented out by Tarun when he was generating avatars previously
export const generateRandomAvatar = (shape, seed, getObject = false) => {
  let topTypeOptionsDefault = [];
  topTypeOptionsDefault.push(
    //   'NoHair',
    //   'Eyepatch',
    //   'Hat',
    //   'Hijab',
    'Turban',
    'WinterHat1',
    'WinterHat2',
    'WinterHat3',
    'WinterHat4',
    //   'LongHairBigHair',
    //   'LongHairBob',
    //   'LongHairBun',
    //   'LongHairCurly',
    //   'LongHairCurvy',
    //   'LongHairDreads',
    //   'LongHairFrida',
    //   'LongHairFro',
    //   'LongHairFroBand',
    //   'LongHairNotTooLong',
    //   'LongHairShavedSides',
    //   'LongHairMiaWallace',
    //   'LongHairStraight',
    //   'LongHairStraight2',
    //   'LongHairStraightStrand',
    'ShortHairDreads01',
    'ShortHairDreads02',
    'ShortHairFrizzle',
    'ShortHairShaggyMullet',
    'ShortHairShortCurly',
    'ShortHairShortFlat',
    'ShortHairShortRound',
    'ShortHairShortWaved',
    'ShortHairSides'
    //   'ShortHairTheCaesar',
    //   'ShortHairTheCaesarSidePart'
  );

  let accessoriesTypeOptionsDefault = [];
  accessoriesTypeOptionsDefault.push(
    'Blank'
    //   'Kurt',
    //   'Prescription01',
    //   'Prescription02',
    //   'Round',
    //   'Sunglasses',
    //   'Wayfarers'
  );

  let facialHairTypeOptionsDefault = [];
  facialHairTypeOptionsDefault.push(
    'Blank',
    //   'BeardMedium',
    'BeardLight'
    //   'BeardMagestic',
    //   'MoustacheFancy',
    //   'MoustacheMagnum'
  );

  let facialHairColorOptionsDefault = [];
  facialHairColorOptionsDefault.push('Black', 'Blonde', 'Brown', 'BrownDark');

  let clotheTypeOptionsDefault = [];
  clotheTypeOptionsDefault.push(
    'BlazerShirt',
    'BlazerSweater',
    'CollarSweater',
    //   'GraphicShirt',
    'Hoodie',
    'Overall',
    'ShirtCrewNeck',
    'ShirtScoopNeck'
    //   'ShirtVNeck'
  );

  let eyeTypeOptionsDefault = [];
  eyeTypeOptionsDefault.push(
    //   'Close',
    //   'Cry',
    'Default',
    //   'Dizzy',
    //   'EyeRoll',
    'Happy'
    //   'Side',
    //   'Squint',
    //   'Surprised',
    //   'Wink',
    //   'WinkWacky'
  );

  let eyebrowTypeOptionsDefault = [];
  eyebrowTypeOptionsDefault.push(
    //   'Angry',
    //   'AngryNatural',
    'Default',
    'DefaultNatural',
    'FlatNatural',
    'RaisedExcited',
    'RaisedExcitedNatural'
    //   'SadConcerned',
    //   'SadConcernedNatural',
    //   'UnibrowNatural',
    //   'UpDown',
    //   'UpDownNatural'
  );

  let mouthTypeOptionsDefault = [];
  mouthTypeOptionsDefault.push(
    //   'Concerned',
    'Default',
    //   'Disbelief',
    //   'Eating',
    //   'Grimace',
    //   'Sad',
    //   'ScreamOpen',
    //   'Serious',
    'Smile'
    //   'Tongue',
    //   'Twinkle',
    //   'Vomit'
  );

  let skinColorOptionsDefault = [];
  skinColorOptionsDefault.push('Yellow', 'Pale', 'Light', 'Brown', 'DarkBrown', 'Black');

  let hairColorOptionsDefault = [];
  hairColorOptionsDefault.push('Black', 'Blonde', 'BlondeGolden', 'Brown', 'BrownDark');

  let hatColorOptionsDefault = [];
  hatColorOptionsDefault.push(
    'Black',
    'Blue01',
    'Blue02',
    'Blue03',
    'Gray01',
    'Gray02',
    'Heather',
    'PastelBlue',
    'PastelOrange',
    'PastelRed',
    'PastelYellow',
    'White'
  );

  let clotheColorOptionsDefault = [];
  clotheColorOptionsDefault.push(
    'Black',
    'Blue01',
    'Blue02',
    'Blue03',
    'Gray01',
    'Gray02',
    'Heather',
    'PastelBlue',
    'PastelOrange',
    'PastelRed',
    'PastelYellow',
    'Pink',
    'Red',
    'White'
  );
  const rng = seed ? seedrandom(seed) : seedrandom();

  const accessoriesType = accessoriesTypeOptionsDefault[Math.floor(rng() * accessoriesTypeOptionsDefault.length)];
  const clotheColor = clotheColorOptionsDefault[Math.floor(rng() * clotheColorOptionsDefault.length)];
  const clotheType = clotheTypeOptionsDefault[Math.floor(rng() * clotheTypeOptionsDefault.length)];
  const eyeType = eyeTypeOptionsDefault[Math.floor(rng() * eyeTypeOptionsDefault.length)];
  const eyebrowType = eyebrowTypeOptionsDefault[Math.floor(rng() * eyebrowTypeOptionsDefault.length)];
  const facialHairColor = facialHairColorOptionsDefault[Math.floor(rng() * facialHairColorOptionsDefault.length)];
  const facialHairType = facialHairTypeOptionsDefault[Math.floor(rng() * facialHairTypeOptionsDefault.length)];
  const hairColor = hairColorOptionsDefault[Math.floor(rng() * hairColorOptionsDefault.length)];
  const hatColor = hatColorOptionsDefault[Math.floor(rng() * hatColorOptionsDefault.length)];
  const mouthType = mouthTypeOptionsDefault[Math.floor(rng() * mouthTypeOptionsDefault.length)];
  const skinColor = skinColorOptionsDefault[Math.floor(rng() * skinColorOptionsDefault.length)];
  const topType = topTypeOptionsDefault[Math.floor(rng() * topTypeOptionsDefault.length)];

  // this is necessary for us to use the same randomly generated avatar for the Avatar component from the Avataaar library
  if (getObject) {
    return {
      avatarStyle: shape || 'Transparent',
      topType,
      accessoriesType,
      facialHairType,
      facialHairColor,
      clotheType,
      eyeType,
      eyebrowType,
      mouthType,
      skinColor,
      hairColor,
      hatColor,
      clotheColor,
    };
  } else {
    return `https://avataaars.io/?accessoriesType=${accessoriesType}&avatarStyle=${
      shape || 'Transparent'
    }&clotheColor=${clotheColor}&clotheType=${clotheType}&eyeType=${eyeType}&eyebrowType=${eyebrowType}&facialHairColor=${facialHairColor}&facialHairType=${facialHairType}&hairColor=${hairColor}&hatColor=${hatColor}&mouthType=${mouthType}&skinColor=${skinColor}&topType=${topType}`;
  }
};
