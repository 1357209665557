import React, { createContext, useReducer } from 'react';
import { useUser } from './UserContext';

const initialState = {
  // frontend = values used to manipulate the frontend
  frontend: {
    openModal: false,
    showResultDetails: false,
    priceRangeSelected: null,
    buildingSelected: null,
    feedbackSubmitted: false,
    buildingAddresses: [],
    selectedBuildingAddresses: [],
    regionsAddresses: [],
    currentTab: 'address',
    address: '',
    // sortType = whether we are sorting the building table in ascending or descending order
    // note: sortType has to be undefined initially
    // otherwise, it seems that you will have to click the sort button twice before the sorting works
    sortType: null,
  },
  // backend = values that will be passed to the backend
  // due to legacy issues, there may be some backend values that actually belong in the frontend (i.e. technically, more refactoring is required)
  backend: {
    method: 'address',
    address: {},
    addressSearched: false,
    search_radius: 10,
    neighbourhood: {},
    buildings: [],
    bedrooms: [1],
    bathrooms: [1],
    building_type: '',
    min_sqft: [''],
    max_sqft: [''],
    higher_floor_premium: 0,
    include_shadow_market: false,
    expectedTraditionalRent: '',
    distanceText: '10mi',
    building_amenities: [],
    area_ave: null,
    neighbourhood_predict: [],
    unitFeatures: [],
    availableOn: null,
    predict: false,
  },
};
export const PriceContext = createContext(initialState);

export const PriceContextProvider = ({ children }) => {
  const { user } = useUser();

  if (user?.views.includes('price+') && ['rentmanager.realsage@gmail.com'].includes(user?.email)) {
    initialState.frontend.currentTab = 'suggestions';
  } else {
    initialState.frontend.currentTab = 'address';
  }

  const [priceState, priceDispatch] = useReducer((priceState, action) => {
    switch (action.type) {
      case 'setFrontend':
        return { ...priceState, frontend: { ...priceState.frontend, ...action.payload } };
      case 'setBackend':
        return { ...priceState, backend: { ...priceState.backend, ...action.payload } };
      case 'resetVariables':
        return initialState;
      default:
        return priceState;
    }
  }, initialState);

  return (
    <PriceContext.Provider
      value={{
        priceState,
        priceDispatch,
      }}>
      {children}
    </PriceContext.Provider>
  );
};
