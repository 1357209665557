import { Form, Panel, InputGroup, FormGroup, FormControl, Schema, HelpBlock } from 'rsuite';
import styled from '@emotion/styled';

export const Model = Schema.Model({
  title: Schema.Types.StringType().isRequired('This field is required'),
  company: Schema.Types.StringType().isRequired('This field is required'),
});
const Step1Form = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors, shadow, space }) => {
  const FormTagline = styled('h4')`
    font-size: 16px;

    font-weight: 400;
    margin-bottom: 30px;
  `;
  const styles = {
    helpblock: {
      maxWidth: '400px',
      fontSize: '13px',
      lineHeight: '21px',
    },
    input: {
      width: '100%',
      maxWidth: '400px',
    },
  };
  return (
    <>
      {space ? (
        <FormTagline>What are you doing day-to-day?</FormTagline>
      ) : (
        <FormTagline className="mb-0">What are you doing day-to-day?</FormTagline>
      )}

      <Form
        checkTrigger="change"
        formValue={roomieProfileData}
        onCheck={setFormErrors}
        formError={formErrors}
        onChange={setroomieProfileData}
        model={Model}
        fluid>
        <Panel shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <FormGroup>
            <HelpBlock>Title / Program</HelpBlock>
            <InputGroup style={styles.input}>
              <FormControl
                value={roomieProfileData.title}
                onChange={val => setroomieProfileData({ ...roomieProfileData, title: val })}
                style={{ background: 'none', width: '100%' }}
                size="xs"
                name="title"
                min={1}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <HelpBlock>Company / School</HelpBlock>
            <InputGroup style={styles.input}>
              <FormControl
                value={roomieProfileData.company}
                onChange={val => setroomieProfileData({ ...roomieProfileData, company: val })}
                style={{ background: 'none', width: '100%' }}
                size="xs"
                name="company"
                min={1}
              />
            </InputGroup>
          </FormGroup>
        </Panel>
      </Form>
    </>
  );
};

export default Step1Form;
