import React from 'react';
import { useUserAuthorization } from 'utils/hooks/useUserAuthorization';
import { PriceContextProvider } from 'contexts/PriceContext';
import { ContextProvider } from 'contexts/LayoutDashboardContext';
import DashboardLayout from 'components/DashboardLayout';
import { LoadingSpinner } from '@/components/ui/spinner';

export function AuthorizedLayout({ user, pageId, children, handleLogout }) {
  const isAuthorized = useUserAuthorization(user, pageId);

  if (!isAuthorized) {
    return <LoadingSpinner />;
  }

  return (
    <PriceContextProvider>
      <ContextProvider>
        <DashboardLayout pageId={pageId} currentUser={user} handleLogout={handleLogout}>
          {children}
        </DashboardLayout>
      </ContextProvider>
    </PriceContextProvider>
  );
}
