import { ScrollArea } from '@/components/ui/scroll-area';
import { useUser } from 'contexts/UserContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GptApi } from 'services/api/gpt';
import { GptHistoryResponse } from 'src/types/Services/GPT/gpt';
import { getQuestionsBeforeYesterday } from 'utils/LeniQ/getQuestionsBeforeYesterday';
import { getTodaysQuestions } from 'utils/LeniQ/getTodaysQuestions';
import { getYesterdaysQuestions } from 'utils/LeniQ/getYesterdaysQuestions';
import LoadingSpinnerAlt from '@/components/ui/spinner-alternate';
import LeniQHistorySection from '../LeniQHistorySection';

interface LeniQHistoryProps {
  handleQuestionClick: (question: string) => void;
}

const LeniQHistory: React.FC<LeniQHistoryProps> = ({ handleQuestionClick }) => {
  const { user: currentUser } = useUser();
  const [loading, setLoading] = useState(false);
  // Array of all leniQ history questions
  const [historyArray, setHistoryArray] = useState([]);

  // Arrays of all prior questions asked today, yesterday, or at an earlier date
  const todaysQuestions = useMemo(() => getTodaysQuestions(historyArray), [historyArray]);
  const yesterdaysQuestions = useMemo(() => getYesterdaysQuestions(historyArray), [historyArray]);
  const olderQuestions = useMemo(() => getQuestionsBeforeYesterday(historyArray), [historyArray]);

  // Get the history of all past leniQ questions asked
  const getHistory = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await GptApi.getHistory(currentUser);
      setLoading(false);
      if (!data.result) setHistoryArray([]);
      // sort data.result by "createdAt" in descending order
      data.result.sort(
        (a: GptHistoryResponse, b: GptHistoryResponse) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      setHistoryArray(data.result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [currentUser]); // Include currentUser as a dependency

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <LoadingSpinnerAlt />
      </div>
    );
  }
  return (
    <ScrollArea className="h-full p-5">
      <div className="flex flex-col gap-3">
        {todaysQuestions.length > 0 && (
          <LeniQHistorySection title="Today" pastSearches={todaysQuestions} handleQuestionClick={handleQuestionClick} />
        )}
        {yesterdaysQuestions.length > 0 && (
          <LeniQHistorySection
            title="Yesterday"
            pastSearches={yesterdaysQuestions}
            handleQuestionClick={handleQuestionClick}
          />
        )}
        {olderQuestions.length > 0 && (
          <LeniQHistorySection title="Older" pastSearches={olderQuestions} handleQuestionClick={handleQuestionClick} />
        )}
      </div>
    </ScrollArea>
  );
};

export default LeniQHistory;
