import React from 'react';

const insightsData = {
  analytics: {
    Management: {
      //always going to be 5
      card1: {
        title: 'Management Card 1',
        data: '$1500',
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card2: {
        title: 'Management Card 2',
        subTitle: '1000',
        data: [1, 2, 3],
        stats: {
          prev: '5%',
          current: '13%',
        },
      },
      card3: {
        title: 'Management Card 3',
        subTitle: '5730',
        data: [1, 2, 3],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card4: {
        title: 'Management Card 4',
        data: '$1000',
        stats: {
          prev: '5%',
          current: '13%',
        },
      },
      card5: {
        title: 'Management Card 5',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      //card 6 similar to insight table, buildings + summation
      card6: {
        title: 'Management Table',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '23%',
        },
      },
    },
    Leasing: {
      //always going to be 5
      card1: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card2: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card3: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card4: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card5: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      //card 6 similar to insight table, buildings + summation
      card6: {
        title: 'Table Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '23%',
        },
      },
    },
    Marketing: {
      //always going to be 5
      card1: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card2: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card3: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card4: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card5: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      //card 6 similar to insight table, buildings + summation
      card6: {
        title: 'Table Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '23%',
        },
      },
    },
    Tenants: {
      //always going to be 5
      card1: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card2: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card3: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card4: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      card5: {
        title: 'Card Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '25%',
        },
      },
      //card 6 similar to insight table, buildings + summation
      card6: {
        title: 'Table Title',
        data: [1, 23, 45],
        stats: {
          prev: '45%',
          current: '23%',
        },
      },
    },
  },
  insights: {
    Management: {
      //3 cards with relevant recommendations and insights
      card1: {
        id: 1,
        category: 'Management',
        message: (
          <div>
            <span className="font-bold">40%</span> of the HVAC work orders amounting to{' '}
            <span className="font-bold">$34,432</span> were caused due to &quot;filter not replaced.&quot; This has
            impacted your NOI by <span className="font-bold">3.5%</span> on average compared to last quarter in{' '}
            <span className="font-bold">Boston</span>.
          </div>
        ),
        version: 'Neutral',
        insight: 'Reduce cost overruns by looking into filter replacement issues.',
        monitoring: false,
      },
      card2: {
        id: 2,
        category: 'Management',
        message: (
          <div>
            You spent <span className="font-bold">37%</span> more on utilities this quarter compared to the last. The
            increase is attributed to <span className="font-bold">Liberty Towers</span> hydro.
          </div>
        ),
        version: 'Neutral',
        insight: (
          <div>
            Increase NOI by reducing Utility costs in the <span className="font-bold">Toronto</span> portfolio.
          </div>
        ),
        monitoring: false,
      },
      card3: {
        id: 3,
        category: 'Management',
        altTitle: 'Revenue Improvement Opportunity',
        message: <div>Your vacancy in Annex neighborhood is lower than the local Market.</div>,
        version: 'Neutral',
        insight: (
          <div>
            Increase the advertised rents by <span className="font-bold">$25</span> on available units.
          </div>
        ),
        change: 'Est. Rev. Increase: $3000',
      },
      //4th card is full width table
      insightTable: {
        data: [
          {
            id: 0,
            address: 'Troy Boston',
            kpi1: 'Boston',
            kpi2: 'The net effective rent per sqft is 3.61% more compared to the average of last quarter.',
            kpi3: '$668555 (-0.1%) ',
          },
          {
            id: 1,
            address: 'Parkway Apartments',
            kpi1: 'Boston',
            kpi2: '58.80% fewer capital expenditures totalling $156,038 was booked this year compared to last year.',
            kpi3: '$685842 (-7.4%) ',
          },
          {
            id: 2,
            address: 'Bridge Condos',
            kpi1: 'Toronto',
            kpi2: '30.06% decrease in budget vs actual variance observed compared to last period.',
            kpi3: '$314215 (+4.8%) ',
          },
          {
            id: 3,
            address: 'Liberty Towers',
            kpi1: 'Toronto',
            kpi2: '4.08% increase in expenses noted. This corelates to more maintenace requests.',
            kpi3: '$416979 (+4.7%) ',
          },
        ],
      },
      //3 more recommendation cards for other insights
      card5: {
        id: 1,
        category: 'Management',
        message: (
          <div>
            <span className="font-bold">Parkway Apartments</span> pending work orders have grown by{' '}
            <span className="font-bold">34%</span> percent. <span className="font-bold">47%</span> of these work orders
            are repeat requests.
          </div>
        ),
        version: 'Neutral',
        insight: (
          <div>
            NEF Improved in <span className="font-bold">Liberty Towers</span> by <span className="font-bold">4.7%</span>{' '}
            after implementing Improvement opportunities delivered last month. requests.
          </div>
        ),
        monitoring: false,
      },
      card6: {
        id: 2,
        category: 'Management',
        altTitle: 'Monitoring Update',
        message: (
          <div>
            NOI Improved in Liberty Towers by <span className="font-bold">4.7%</span> after implementing marketing cost
            change actions.
          </div>
        ),
        version: 'Neutral',
        monitoring: true,
      },
      card7: null,
    },
    Leasing: {
      //3 cards with recommendations and insights
      card1: {
        id: 1,
        category: 'Leasing',
        message: (
          <div>
            The conversion rate on the leads has reduced by <span className="font-bold">5%</span> due to an average{' '}
            <span className="font-bold">18%</span> reduction in available units in{' '}
            <span className="font-bold">Boston</span>. <span className="font-bold">85%</span> of current and upcoming
            units are 1-bed units, while <span className="font-bold">55%</span> of leasing activities in the current
            period are done on 2-bedroom prospects for the portfolio.
          </div>
        ),
        version: 'Neutral',
        insight: 'Focus specifically on 1 bedroom inquiries to boost conversions.',
        monitoring: false,
      },
      card2: {
        id: 2,
        altTitle: 'Revenue Improvement Opportunity',
        category: 'Leasing',
        message: (
          <div>
            Your marketed rent is <span className="font-bold">10%</span> below the recent average in{' '}
            <span className="font-bold">Toronto</span>. Your portfolio shows a <span className="font-bold">23%</span>{' '}
            renewal growth and an average <span className="font-bold">2.10%</span> conversion rate.
          </div>
        ),
        version: 'Neutral',
        insight: 'Good time to increase rental pricing. This can improve effective rents.',
        change: 'Est. Rev. Increase: $17,784',
        monitoring: false,
      },
      card3: {
        id: 3,
        category: 'Leasing',
        message: (
          <div>
            <span className="font-bold">55%</span> more people showed up to the showings in{' '}
            <span className="font-bold">Boston</span> with activities one day before the showing day. You have an
            average of <span className="font-bold">12%</span> no-shows across <span className="font-bold">Boston</span>.
          </div>
        ),
        version: 'Neutral',
        insight: 'Reduce No shows by setting up pre-showing follow-ups.',
        monitoring: false,
      },
      //4th card is full width table
      insightTable: {
        data: [
          {
            id: 0,
            address: 'Bridge Condos',
            kpi1: 'Toronto',
            kpi2: 'The team has attained 110% of the budgeted occupancy for the building.',
            kpi3: '99.33%',
          },
          {
            id: 1,
            address: 'Parkway Apartments',
            kpi1: 'Boston',
            kpi2: '20% more propects have been lost due to lacking amenities compared to last period.',
            kpi3: '98.90%',
          },
          {
            id: 2,
            address: 'Liberty Towers',
            kpi1: 'Toronto',
            kpi2: '8% increase in lease velocity was observed due to 20% more leads with longer move-in timeframes.',
            kpi3: '99.34%',
          },
        ],
      },
      //3 more recommendation cards for other insights
      card5: {
        id: 1,
        category: 'Marketing',
        message: (
          <div>
            <span className="font-bold">Troy Boston</span> has a conversion rate of{' '}
            <span className="font-bold">3.30%</span> and <span className="font-bold">Parkway Apartments</span> has a
            conversion rate of <span className="font-bold">2.04%</span>.{' '}
            <span className="font-bold">Parkway Apartments</span> gets <span className="font-bold">33%</span> more leads
            resulting in a mismatch with availability in <span className="font-bold">Parkway Apartments</span>.
          </div>
        ),
        version: 'Neutral',
        insight: (
          <div>
            Optimise conversion with shared lead distribution between <span className="font-bold">Troy Boston</span> and{' '}
            <span className="font-bold">Parkway Apartments</span>.
          </div>
        ),
        monitoring: false,
      },
      card6: {
        id: 2,
        altTitle: 'Monitoring Update',
        category: 'Marketing',
        message: (
          <div>
            Actions on market pricing resulted in over <span className="font-bold">4%</span> improvement in effective
            net rents across the <span className="font-bold">Boston</span> portfolio.
          </div>
        ),
        version: 'Neutral',
        monitoring: true,
      },
      card7: {
        id: 3,
        altTitle: 'Monitoring Update',
        category: 'Marketing',
        message: (
          <div>
            Lease velocity is reduced by <span className="font-bold">16%</span> post accepting performance insight last
            quarter.
          </div>
        ),
        version: 'Neutral',
        monitoring: true,
      },
    },
    Tenants: {
      //3 cards with recommendations and insights
      card1: {
        id: 1,
        category: 'Tenants',
        message: (
          <div>
            There has been an <span className="font-bold">17%</span> increase in pending workorders across{' '}
            <span className="font-bold">Toronto</span>, this corresponds to <span className="font-bold">6.72%</span>{' '}
            decrese in NPS in this period.
          </div>
        ),
        version: 'Neutral',
        insight: 'Improve the NPS score by acting on the requests faster.',
        monitoring: false,
      },
      card2: null,
      card3: null,
      //4th card is full width table
      insightTable: {
        data: [
          {
            id: 0,
            address: 'Troy Boston',
            kpi1: 'Boston',
            kpi2: '8.85% increase in the average tenancy in the building was noticed when compared to the last period.',
            kpi3: '61 (+4.5%) ',
          },
          {
            id: 1,
            address: 'Parkway Apartments',
            kpi1: 'Boston',
            kpi2: '11.84% increase in lease expiration exposure compared to the last period.',
            kpi3: '70 (+4.3%) ',
          },
          {
            id: 2,
            address: 'Liberty Towers',
            kpi1: 'Toronto',
            kpi2: '67% fewer notices were received during this period than the previous one.',
            kpi3: '64 (-3.2%) ',
          },
        ],
      },
      //3 more recommendation cards for other insights
      card5: {
        id: 1,
        category: 'Tenants',
        altTitle: 'Monitoring Update',
        message: (
          <div>
            <span className="font-bold">39.9%</span> less work orders were raised in{' '}
            <span className="font-bold">Troy Boston</span> this quarter after preventive maintenance actions were taken.
          </div>
        ),
        version: 'Neutral',
        monitoring: false,
      },
      card6: null,
      card7: null,
    },
    Marketing: {
      //3 cards with recommendations and insights
      card1: {
        id: 1,
        altTitle: 'Seasonal Trend Identified',
        category: 'Marketing',
        message: (
          <div>
            <span className="font-bold">0%</span> conversion through Zumper and an avg.{' '}
            <span className="font-bold">8%</span> conversion on Facebook over the past 5 weeks.
          </div>
        ),
        version: 'Neutral',
        insight: 'Reallocating budgets to Facebook will optimize spending.',
        change: 'Est. savings $5302',
        monitoring: false,
      },
      card2: {
        id: 2,
        category: 'Marketing',
        message: (
          <div>
            Upcoming vacancies in the coming month across the <span className="font-bold">Toronto</span> portfolio have
            reduced by <span className="font-bold">57%</span> compared to the last period. The overall vacancy rate in{' '}
            <span className="font-bold">Toronto</span> Portfolio is <span className="font-bold">1.10%</span>
          </div>
        ),
        version: 'Neutral',
        insight: (
          <div>
            Reducing marketing spending on selected portfolios by <span className="font-bold">30%</span> could save
            budgets.
          </div>
        ),
        change: 'Est. savings $1155',
        monitoring: false,
      },
      card3: {
        id: 3,
        category: 'Marketing',
        message: (
          <div>
            The vacancy rate is expected to go down by another <span className="font-bold">23%</span>
          </div>
        ),
        version: 'Neutral',
        insight: (
          <div>
            Reduce marketing spend by <span className="font-bold">10%</span> in{' '}
            <span className="font-bold">Boston</span>.
          </div>
        ),
        change: 'Est. saving $400',
        monitoring: false,
      },
      //4th card is full width table
      insightTable: {
        data: [
          {
            id: 0,
            address: 'Troy Boston',
            kpi1: 'Boston',
            kpi2: 'Upcoming Vacancy is down by 75% compared to this time last year. Same external trends were detected in the neighbourhood and the local market.',
            kpi3: '$4423 (-24.5%)',
          },
          {
            id: 1,
            address: 'Parkway Apartments',
            kpi1: 'Boston',
            kpi2: '75% more leases are being signed via Facebook leads for this building compared to last quarter. The conversion rates from Facebook are 3.11% compared to 1.74% during the last quarter.',
            kpi3: '$6800 (-12.8%)',
          },
          {
            id: 2,
            address: 'Bridge Condos',
            kpi1: 'Toronto',
            kpi2: 'Tenant acquisition costs are down by 6%, mainly driven by higher conversion on website leads and Facebook.',
            kpi3: '$2500 (-71.2%) ',
          },
        ],
      },
      //3 more recommendation cards for other insights
      card5: {
        id: 1,
        category: 'Marketing',
        message: (
          <div>
            The Tenant acquisition cost in <span className="font-bold">Parkway Apartments</span> has increased by{' '}
            <span className="font-bold">7%</span>, compared to no change in{' '}
            <span className="font-bold">Troy Boston</span>.
          </div>
        ),
        version: 'Neutral',
        insight: (
          <div>
            Optimize leads by considering a spend change for <span className="font-bold">Parkway Apartments</span>.
          </div>
        ),
        change: '',
        monitoring: false,
      },
      card6: {
        id: 2,
        category: 'Marketing',
        altTitle: 'Monitoring Update',
        message: (
          <div>
            You are getting <span className="font-bold">14%</span> more leads on Direct to Website across{' '}
            <span className="font-bold">Toronto</span> with an average <span className="font-bold">9.96%</span>{' '}
            conversion rate. The change to allocate higher percentage of marketing budget to website has saved you{' '}
            <span className="font-bold">$459</span> this quarter.
          </div>
        ),
        version: 'Neutral',
        insight: '',
        change: '',
        monitoring: true,
      },
      card7: {
        id: 3,
        category: 'Marketing',
        altTitle: 'Monitoring Update',
        message: (
          <div>
            Changing the landing page and completing the property profile for{' '}
            <span className="font-bold">Liberty Towers</span> has resulted in a <span className="font-bold">24%</span>{' '}
            increase in web leads for that property.
          </div>
        ),
        version: 'Neutral',
        insight: '',
        change: '',
        monitoring: true,
      },
    },
  },
  validUsers: [
    'zackory@leni.co',
    'dev.prod.soulrooms@gmail.com',
    'volkan@leni.co',
    'leon@leni.co',
    'karl@leni.co',
    'tarun@leni.co',
  ],
};

export default insightsData;
