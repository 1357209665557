export default function Styles() {
  return (
    <style>
      {`
      .go-to-prop{
        color:#e0ac00 !important;
      }
      .cus-nav-resident ul{
        
        display:flex;
        align-items:start;
        padding-top:6px
      }
      .rs-navbar-header{
        display:grid;
        place-items:center;
        height:66px;
      }
      
      .ob-logo{
        width: 120px;
      }
       /* .layout-page-main-container{
  
  .db-navbar {
    margin: 2px 0 0px 0;
    padding: 0 2px 0 25px;
    .link {
      color: #575757;
      font-size: 15px;
    }
    .link:hover {
      text-decoration: none;
    }
    .res-navitem {
      margin: 10px;
    }
    .rs-navbar-header {
      padding: 8px 8px 8px 0px;
    }
    .rs-nav.rs-nav-default {
      margin-right: 34px;
  }
    button {
      color: #FFB900;
      font-size: 15px;
      border-color: #FFB900;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 25px;
      background-color: transparent;
      margin: 5px;
    }
    .area-dropdown {
      margin-left: 200px;
    }
  }
  .ava-header{
    padding: 7px !important;
  }
  .rs-btn.rs-btn-ghost {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .rs-header {
    box-shadow: 0px 2px 22px -5px rgba(0, 0, 0, 0.21);
    -webkit-box-shadow: 0px 2px 22px -5px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 2px 22px -5px rgba(0, 0, 0, 0.21);
    z-index: 10;
  }
  .rs-navbar-body {
    height: 66px;
    padding: 8px 0;
  }
  .db-navbar button {
    margin: 1px 5px 5px 5px !important;
  }
  body {
    background-color: #F9F9F9;
    
  }
  .db-content {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: 1 1;
    padding: 0 0px;
    margin: 0;
  }
  
  .rs-panel {
    background: #ffffffbd;
    margin-right: 10px;
  }
  .select-search {
    margin: 5px 100px;
  }
  .hamburger-btn {
    display: none;
  }
  @media screen and(max-width:768px) {
    .area-dropdown {
      position: relative;
      bottom: 30px;
    }
    .rs-btn.rs-btn-ghost {
      display: none;
    }
    .rs-navbar-nav.rs-navbar-right {
      display: none;
    }
    .hamburger-btn {
      display: flex;
      position: relative;
      right: 25px;
    }
    .ob-logo {
      display: none;
    }
    .rs-btn.rs-btn-icon {
      top: 5px;
      left: 300px;
    }
    
  }
  @media screen and(max-width:415px) {
    .area-dropdown {
      display: none;
    }
  }
} */


.layout-page-main-container .db-navbar {
  margin: 2px 0 0px 0;
  padding: 0 2px 0 25px;
}
.layout-page-main-container .db-navbar .link {
  color: #575757;
  font-size: 15px;
}
.layout-page-main-container .db-navbar .link:hover {
  text-decoration: none;
}
.layout-page-main-container .db-navbar .res-navitem {
  margin: 10px;
}
.layout-page-main-container .db-navbar .rs-navbar-header {
  padding: 8px 8px 8px 0px;
}
.layout-page-main-container .db-navbar .rs-nav.rs-nav-default {
  margin-right: 34px;
}
.layout-page-main-container .db-navbar button {
  color: #FFB900;
  font-size: 15px;
  border-color: #FFB900;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 25px;
  background-color: transparent;
  margin: 5px;
}
.layout-page-main-container .db-navbar .area-dropdown {
  margin-left: 200px;
}
.layout-page-main-container .ava-header {
  padding: 7px !important;
}
.layout-page-main-container .rs-btn.rs-btn-ghost {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
}
.layout-page-main-container .rs-header {
  box-shadow: 0px 2px 22px -5px rgba(0,0,0,0.21);
  -webkit-box-shadow: 0px 2px 22px -5px rgba(0,0,0,0.21);
  -moz-box-shadow: 0px 2px 22px -5px rgba(0,0,0,0.21);
  z-index: 10;
}
.layout-page-main-container .rs-navbar-body {
  height: 66px;
  padding: 8px 0;
}
.layout-page-main-container .db-navbar button {
  margin: 1px 5px 5px 5px !important;
}
.layout-page-main-container body {
  background-color: #F9F9F9;
}
.layout-page-main-container .db-content {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 1;
  padding: 0 0px;
  margin: 0;
}
.layout-page-main-container .rs-panel {
  background: #ffffffbd;
  margin-right: 10px;
}
.layout-page-main-container .select-search {
  margin: 5px 100px;
}
.layout-page-main-container .hamburger-btn {
  display: none;
}
@media screen and (max-width: 768px) {
  .layout-page-main-container .area-dropdown {
    position: relative;
    bottom: 30px;
 }
  .layout-page-main-container .rs-btn.rs-btn-ghost {
    display: none;
 }
  .layout-page-main-container .rs-navbar-nav.rs-navbar-right {
    display: none;
 }
  .layout-page-main-container .hamburger-btn {
    display: flex;
    position: relative;
    right: 25px;
 }
  .layout-page-main-container .ob-logo {
    display: none;
    
 }
  .layout-page-main-container .rs-btn.rs-btn-icon {
    top: 5px;
    left: 300px;
 }
}
@media screen and (max-width: 415px) {
  .layout-page-main-container .area-dropdown {
    display: none;
 }
}
.cus-db-nav{
  height:74px
}
@media screen and (max-width:769px){
  .cus-db-nav{
    height:66px
  }
  .layout-page-main-container .rs-navbar-body {
    height: 66px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }
  
}

        `}
    </style>
  );
}
