export const TABLEAU_DASHBOARDS = [
  {
    pageId: 'executiveSummary',
    title: 'Executive Summary',
    dashboardTabs: [
      {
        value: 0,
        label: 'Executive Summary',
        url: process.env.NEXT_PUBLIC_TABLEAU_EXEC_SUMMARY_URL,
      },
    ],
  },
  {
    pageId: 'financialPerformance',
    title: 'Financial Performance',
    dashboardTabs: [
      {
        value: 0,
        label: 'Collection and Delinquency',
        url: process.env.NEXT_PUBLIC_TABLEAU_COLLECTION_DELINQUENCY_URL,
      },
      {
        value: 1,
        label: 'Account Budget-Actuals',
        url: process.env.NEXT_PUBLIC_TABLEAU_ACCOUNT_BUDGET_ACTUALS_URL,
      },
      {
        value: 2,
        label: 'T12 Account Trees',
        url: process.env.NEXT_PUBLIC_TABLEAU_T12_ACCOUNT_TREES_URL,
      },
    ],
  },
  {
    pageId: 'marketingActivity',
    title: 'Marketing Activity',
    dashboardTabs: [
      {
        value: 0,
        label: 'Marketing Activity',
        url: process.env.NEXT_PUBLIC_TABLEAU_MARKETING_ACTIVITY_URL,
      },
    ],
  },
  {
    pageId: 'occupancyAndLeasing',
    title: 'Occupancy and Leasing',
    dashboardTabs: [
      {
        value: 0,
        label: 'Occupancy',
        url: process.env.NEXT_PUBLIC_TABLEAU_OCCUPANCY_URL,
      },
      {
        value: 1,
        label: 'Lease Trade Outs',
        url: process.env.NEXT_PUBLIC_TABLEAU_LEASE_TRADEOUTS_URL,
      },
      {
        value: 2,
        label: 'Lease and Renewal Activity',
        url: process.env.NEXT_PUBLIC_TABLEAU_LEASE_AND_RENEWAL_ACTIVITY_URL,
      },
    ],
  },
  {
    pageId: 'residentActivity',
    title: 'Resident Activity',
    dashboardTabs: [
      {
        value: 0,
        label: 'Resident Activity',
        url: process.env.NEXT_PUBLIC_TABLEAU_RESIDENT_ACTIVITY_URL,
      },
      {
        value: 1,
        label: 'Calendar',
        url: process.env.NEXT_PUBLIC_TABLEAU_CALENDAR_URL,
      },
    ],
  },
  {
    pageId: 'workOrders',
    title: 'Work Orders',
    dashboardTabs: [
      {
        value: 0,
        label: 'Work Orders',
        url: process.env.NEXT_PUBLIC_TABLEAU_WORK_ORDERS_URL,
      },
    ],
  },
  {
    pageId: 'reporting',
    title: 'Market Surveys',
    dashboardTabs: [
      {
        value: 0,
        label: 'Revenue Management',
        url: null,
      },
    ],
  },
  {
    pageId: 'unit_turn',
    title: 'Unit Turn Data',
    dashboardTabs: [
      {
        value: 0,
        label: 'Unit Turn Data',
        url: null,
      },
    ],
  },
  {
    pageId: 'budgeted_vs_actual',
    title: 'Budgeted vs. Actual',
    dashboardTabs: [
      {
        value: 0,
        label: 'Budgeted vs. Actual',
        url: null,
      },
    ],
  },
];
