import React from 'react';
import Image from 'next/image.js';
import { Col, Grid, Row } from 'rsuite';
import { Container, Content, FlexboxGrid, Header, Navbar } from 'rsuite';
import Styles from './styles.js';
import { Link } from 'utils/with-i18next';
import PropTypes from 'prop-types';
import ChatbotHide from 'components/ChatbotHide';

function MaintenanceLayout({ children }) {
  const NavbarStyles = {
    padding: 18,
    fontSize: 16,
    background: '#fff',
    color: ' #fff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };

  return (
    <>
      <ChatbotHide show={false} debug={true} />
      <main>
        <Grid fluid>
          <Row className="maintenance-container">
            <Col xs={24} sm={24} md={10}>
              <Container>
                <Header>
                  <Navbar style={NavbarStyles}>
                    <Navbar.Header>
                      <Link href={'https://www.leni.co'} passHref>
                        <Image
                          className="logo1"
                          src="/static/images/BigLogo.png"
                          alt="Leni Logo"
                          height={35}
                          width={35}
                        />
                      </Link>
                    </Navbar.Header>
                  </Navbar>
                </Header>
              </Container>
            </Col>
          </Row>
          <Row>
            <Content className="maintenance-content">
              <FlexboxGrid justify="space-around">
                <FlexboxGrid.Item componentClass={Col} xs={20} sm={18} md={15}>
                  <Content>{children}</Content>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Content>
          </Row>
        </Grid>
      </main>
      <Styles />
    </>
  );
}

MaintenanceLayout.propTypes = {
  children: PropTypes.node,
};

export default MaintenanceLayout;
