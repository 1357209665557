import Styles from './styles.js';
import Image from 'next/image.js';
import { useState, useEffect } from 'react';
import { Container, Header, Navbar, Nav, Content, Button, Dropdown, Alert } from 'rsuite';
import { Link } from 'utils/with-i18next';
import AvatarComponent from 'components/Avatar';
import { AuthApi } from 'services/api';
import { useSWRConfig } from 'swr';
import { Router } from 'utils/with-i18next';
import { createErrorStatusCodeHandler } from 'utils/default-http-error-handler';
import { BookingApi } from 'services/api/booking';
import { startCase } from 'lodash';
import AddRoomieProfileModal from 'components/Modals/RoomieProfile';
import { useUser } from 'contexts/UserContext.tsx';
import { useErrorTracker } from 'utils/use-error-tracker';
import { useRouter } from 'next/router.js';

function ResidentsPropertyLayout({ children, currentUser }) {
  const { cache } = useSWRConfig();
  const errorTracker = useErrorTracker();
  const [bookings, setBookings] = useState([]);
  const [roommateProfileModal, setRoommateProfileModal] = useState(false);
  const { isUserLoggedIn, clearUser } = useUser();
  const router = useRouter();

  const handleLogout = () => {
    AuthApi.logout()
      .then(() => {
        cache.clear();
        clearUser();
        router.push('/login');
      })
      .catch(
        createErrorStatusCodeHandler(
          'logout',
          () => {
            Alert.error(`Something went wrong while logging out.`, 5000);
          },
          null,
          errorTracker
        )
      );
  };

  useEffect(() => {
    if (currentUser) {
      BookingApi.fetchBookings('tenant')
        .then(res => setBookings(res.data))
        .catch(err => {
          errorTracker.error('Fetch bookings error', { fetchBookingsParam: 'tenant' });
          Alert.error('Something went wrong while fetching bookings.', 5000);
          console.error(err);
        });
    }
  }, [currentUser, errorTracker]);

  const [urlPathname, setUrlPathname] = useState('');
  useEffect(() => {
    setUrlPathname(window.location.pathname);
  }, []);

  return (
    <>
      <main className="layout-page-main-container">
        <Container id="app-root" style={{ height: '100%' }}>
          <Container id="container-opacity" style={{ height: '100%' }}>
            <Header className="">
              <Navbar className="db-navbar cus-db-nav">
                <Navbar.Header>
                  <Link href={'/'} passHref>
                    <Image
                      className="ob-logo cursor-pointer"
                      src="/static/images/leni-logo-black.svg"
                      alt="Leni Logo"
                      height={50}
                      width={50}
                    />
                  </Link>
                </Navbar.Header>
                <Navbar.Body>
                  <div className="flex justify-between items-center md:hidden w-full ">
                    <Image src="/static/images/SmallLogo.svg" height={35} width={35} alt="Leni Logo" />
                    <div className="mr-4">
                      <Link href="/login?tenant=true">
                        <Button>Login</Button>
                      </Link>
                    </div>
                  </div>
                  <Nav className="cus-nav-resident" pullRight>
                    {!isUserLoggedIn() ? (
                      <Link href="/login?tenant=true">
                        <Button className=" ">Login</Button>
                      </Link>
                    ) : (
                      <Dropdown
                        toggleClassName="ava-header"
                        placement="bottomEnd"
                        className="animated fadeIn mr-2"
                        renderTitle={() => {
                          return (
                            currentUser?.displayName && <AvatarComponent size="md" name={currentUser?.displayName} />
                          );
                        }}>
                        <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                          <p>Signed in as</p>
                          <strong>{currentUser?.displayName}</strong>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <Dropdown.Menu title="My Rentals" pullLeft={true}>
                          {bookings.length > 0 ? (
                            bookings
                              .filter(
                                item =>
                                  item.status === 'awaiting_application' ||
                                  item.status === 'paid' ||
                                  item.status === 'approved' ||
                                  item.status === 'documents-uploaded'
                              )
                              .map(booking => {
                                return (
                                  <Dropdown.Item
                                    key={booking.id}
                                    onSelect={() => Router.push(`/my-bookings/${booking?.id}`)}>
                                    {booking.room
                                      ? `${startCase(booking?.room.type)} room in ${booking?.propertyAddress}`
                                      : `Entire Unit in ${booking?.propertyAddress}`}
                                  </Dropdown.Item>
                                );
                              })
                          ) : (
                            <Dropdown.Item disabled>No Rentals</Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                        <Dropdown.Item divider />
                        <Dropdown.Item onSelect={handleLogout}>Sign Out</Dropdown.Item>
                      </Dropdown>
                    )}
                  </Nav>
                </Navbar.Body>
              </Navbar>
            </Header>

            <Content className="db-content">{children}</Content>
          </Container>
        </Container>
        <AddRoomieProfileModal show={roommateProfileModal} setClose={() => setRoommateProfileModal(false)} />
      </main>
      <Styles />
    </>
  );
}

export default ResidentsPropertyLayout;
