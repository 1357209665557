export default function Style() {
  return (
    <style>
      {`
      .go-to-dashboard{
        color: #ffb900;
      }
        .generic-bg {
    height: 100vh;
    position: relative;
    clip-path: polygon(125px 0%, 1600px 0%, 1600px 100%, 0 100%);
}


.generic-container  .rs-col {
    padding: 0 !important;
}

.generic-container  .rs-footer {
    text-align: center;
    font-size: 14px;
}

.logo {
    height: 50%;
    margin: 0 30px;
    cursor: pointer;
}


.generic-content {
    height: 100%;
    background-image: url('/static/images/auth-layout-bg.png');
    background-color: #f8f8f8;
    background-position: right;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: RIGHT;
    BACKGROUND-SIZE: 400px;
}

       
        `}
    </style>
  );
}
