import { GptHistoryResponse } from 'src/types/Services/GPT/gpt';

export const getYesterdaysQuestions = (data: GptHistoryResponse[]) => {
  const now = new Date();
  const startOfYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0);
  const endOfYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);

  // Filter the items created yesterday and store them in yesterdayArray
  const yesterdayItems = data.filter(item => {
    const createdAt = new Date(item.createdAt);
    return createdAt >= startOfYesterday && createdAt <= endOfYesterday;
  });

  return yesterdayItems;
};
