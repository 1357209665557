import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Maximize2, Minimize2, Sparkles, X } from 'lucide-react';
import React, { useState } from 'react';
import useInsightContext from 'utils/hooks/useInsightContext';

const InsightHeader: React.FC = () => {
  const [maximized, setMaximized] = useState(false);
  const {
    state: { activeInsight, insightsDrawerOpen, activeTab },
    dispatch: insightDispatch,
  } = useInsightContext();

  const toggleInsights = () => {
    insightDispatch({
      type: 'SET_INSIGHTS_DRAWER_OPEN',
      payload: !insightsDrawerOpen,
    });
  };

  const toggleActiveTab = () => {
    insightDispatch({
      type: 'SET_ACTIVE_TAB',
      payload: activeTab === 'history' ? 'insight' : 'history',
    });
  };
  return (
    <>
      <div className="flex justify-between items-center mb-2 pb-3">
        <div className="flex gap-1 items-center text-gray-900">
          <Sparkles height={20} width={20} />
          <h3 className="text-lg ml-1">Insights</h3>
        </div>
        <div className="flex items-center gap-2">
          <span
            className={`relative cursor-pointer ${
              activeTab === 'insight' ? 'text-primary-900 font-bold' : 'text-gray-400 font-normal group'
            }`}
            onClick={toggleActiveTab}>
            Insight
            <span
              className={`${
                activeTab === 'insight'
                  ? 'absolute bottom-0 left-0 h-[1px] w-full bg-primary-900'
                  : 'absolute bottom-0 left-1/2 h-[1px] w-0 bg-primary-900 transition-all duration-300 ease-in-out group-hover:w-full group-hover:left-0'
              }`}
            />
          </span>
          <span
            className={`relative cursor-pointer ${
              activeTab === 'history' ? 'text-primary-900 font-bold' : 'text-gray-400 font-normal group'
            }`}
            onClick={toggleActiveTab}>
            Monitoring
            <span
              className={`${
                activeTab === 'history'
                  ? 'absolute bottom-0 left-0 h-[1px] w-full bg-primary-900'
                  : 'absolute bottom-0 left-1/2 h-[1px] w-0 bg-primary-900 transition-all duration-300 ease-in-out group-hover:w-full group-hover:left-0'
              }`}
            />
          </span>
        </div>

        <div className="flex items-center gap-2">
          <TooltipProvider>
            {maximized ? (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Minimize2
                    height={15}
                    width={15}
                    className="cursor-pointer text-gray-400"
                    onClick={() => setMaximized(false)}
                  />
                </TooltipTrigger>
                <TooltipContent
                  side="bottom"
                  align="end"
                  sideOffset={8}
                  className="z-[1000] bg-white-50 p-3 border-[1px] border-gray-50 shadow-xl rounded-lg">
                  <p className="text-gray-400">Minimize View</p>
                </TooltipContent>
              </Tooltip>
            ) : (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Maximize2
                    height={15}
                    width={15}
                    className="cursor-pointer text-gray-400"
                    onClick={() => setMaximized(true)}
                  />
                </TooltipTrigger>
                <TooltipContent
                  side="bottom"
                  align="end"
                  sideOffset={8}
                  className="z-[1000] bg-white-50 p-3 border-[1px] border-gray-50 shadow-xl rounded-lg">
                  <p className="text-gray-400">Maximize View</p>
                </TooltipContent>
              </Tooltip>
            )}
          </TooltipProvider>
          <X height={20} width={20} className="text-gray-400 cursor-pointer" onClick={toggleInsights} />
        </div>
      </div>
      {activeTab === 'insight' && (
        <div className="flex items-center gap-2 w-full text-accent-500 border-[1px] border-accent-500 p-2 rounded-md mb-5">
          <Sparkles height={25} width={25} />
          <p className="text-sm font-bold">{activeInsight?.insightSummary}</p>
        </div>
      )}
    </>
  );
};

export default InsightHeader;
