import { TABLEAU_DASHBOARDS } from 'data/tableauDashboards';
import { User } from 'src/types/User/user';
import { LOOKER_DASHBOARD_CATEGORIES } from 'utils/Navigation/constants';

export const getTableauDashboardCategories = (user: User | null) => {
  if (!user) return [];
  if (user.email === 'dev.prod.soulrooms@gmail.com') return TABLEAU_DASHBOARDS;
  return user.dashboardViews?.includes('reporting')
    ? TABLEAU_DASHBOARDS.filter(
        dashboard => dashboard.pageId !== 'unit_turn' && dashboard.pageId !== 'budgeted_vs_actual'
      )
    : TABLEAU_DASHBOARDS.filter(
        dashboard =>
          dashboard.pageId !== 'reporting' &&
          dashboard.pageId !== 'unit_turn' &&
          dashboard.pageId !== 'budgeted_vs_actual'
      );
};

export const getLookerDashboardCategories = (user: User | null) => {
  if (!user) return [];
  if (user.dashboardViews?.length === 0 && user.views?.includes('price+') && user.views?.includes('dashboard'))
    return [LOOKER_DASHBOARD_CATEGORIES.find(cat => cat.pageId === 'reporting')];
  if (user.email === 'dev.prod.soulrooms@gmail.com') {
    return LOOKER_DASHBOARD_CATEGORIES.filter(category =>
      user?.dashboardViews?.find(
        view =>
          view === category.pageId && !['overview', 'marketing', 'leasing', 'management', 'tenants'].includes(view)
      )
    );
  }
  return LOOKER_DASHBOARD_CATEGORIES.filter(category => user?.dashboardViews?.find(view => view === category.pageId));
};

export const isTableauDashboard = (item: object) => {
  return 'dashboardTabs' in item;
};
