import React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles.js';
import SlackFeedback from 'react-slack-feedback';
import { compact } from 'lodash';
import { TicketApi } from 'services/api/ticket';
import { useRouter } from 'next/router';

function SlackFeedbackComponent({ user, categories, isOpen, onClose }) {
  const defaultCategories = ['General', 'Bug', 'Feedback'];
  const { pathname } = useRouter();
  return (
    <>
      <SlackFeedback
        open={isOpen}
        onClose={onClose}
        translations={{
          'header.title': 'Contact Support  ',
          'submit.text': 'Send Message',
          'trigger.text': 'Support',
          'label.type': 'Type',
        }}
        showIcon={false}
        theme={{
          fontFamily: " 'Josefin Sans', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif",
          colors: { primary: '#333', secondary: '#302B2B' },
          trigger: {
            fontWeight: '600',
            backgroundColor: '#ffc900',
            hoverBackgroundColor: '#ffc900',
            borderRadius: '180px',
            hoverColor: '#302B2B',
            color: '#302B2B',
          },
          header: {
            backgroundColor: '#ffc900',
          },
          label: {
            color: '#333333',
          },
        }}
        showChannel={false}
        feedbackTypes={(categories || defaultCategories).map(c => ({ value: `${c} on page(${pathname})`, label: c }))}
        user={`${user?.displayName} <${compact([user?.email, user?.phone]).join(' | ')}>`}
        onSubmit={(payload, success, error) => {
          TicketApi.sendToSlack(payload).then(success).catch(error);
        }}
        onImageUpload={(file, success, error) => {
          TicketApi.uploadSlackImage(file)
            .then(({ data }) => success(data.url))
            .catch(error);
        }}
      />
      <Styles />
    </>
  );
}

SlackFeedbackComponent.propTypes = {
  channel: PropTypes.string,
  categories: PropTypes.array,
  user: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
};

export default SlackFeedbackComponent;
