import { Button, Modal } from 'rsuite';
import { useEffect, useState } from 'react';
import Styles from './styles.js';
import PropTypes from 'prop-types';
import RoomieProfileSteps from 'components/RoomieProfileSteps';

function AddRoomieProfileModal({ show, setClose }) {
  const [width, setWidth] = useState(null);
  const [loading] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Modal
      backdrop={true}
      className={width <= 600 ? 'xxs-size' : ''}
      size={width <= 600 ? 'xs' : width <= 820 ? 'sm' : 'md'}
      show={show}
      onHide={() => setClose(false)}>
      <Modal.Header>{/* <Modal.Title>Add RoomieProfile</Modal.Title> */}</Modal.Header>
      <Modal.Body>
        <RoomieProfileSteps setClose={setClose} isModel={true} customSize={true} mdsize={true} offsetsize={true} />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="link" size="xs" className="closeBtn " onClick={setClose} loading={loading} disabled={false}>
          Close
        </Button>
      </Modal.Footer>
      <Styles />
    </Modal>
  );
}

AddRoomieProfileModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
};

export default AddRoomieProfileModal;
