import { Grid, Row, Col } from 'rsuite';
import { Panel, FormGroup, Schema, Form, FormControl, Radio, RadioGroup } from 'rsuite';
import styled from '@emotion/styled';

export const Model = Schema.Model({
  cookForOthers: Schema.Types.StringType().isRequired('This field is required'),
  eatTogethers: Schema.Types.StringType().isRequired('This field is required'),
  hangoutTogether: Schema.Types.StringType().isRequired('This field is required'),
});
const Step8Form = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors, shadow, space }) => {
  const FormTagline = styled('h4')`
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
  `;

  return (
    <div>
      {space ? (
        <FormTagline>
          Would you like to <span className="text-decoration-underline">cook</span> for each other?
        </FormTagline>
      ) : (
        <FormTagline className="mb-3">
          Would you like to <span className="text-decoration-underline">cook</span> for each other?
        </FormTagline>
      )}

      <Form
        checkTrigger="change"
        formValue={roomieProfileData}
        onCheck={setFormErrors}
        formError={formErrors}
        onChange={setroomieProfileData}
        model={Model}
        fluid>
        <Panel className="mt-2" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="cookForOthers"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, cookForOthers: val })}>
                    <Radio value="I'd prefer not to">I&apos;d prefer not to</Radio>
                    <Radio value="Once in a while">Once in a while</Radio>
                    <Radio value="As often as possible">As often as possible</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-0">
          Would you like to <span className="text-decoration-underline">eat</span> together?
        </FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="eatTogethers"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, eatTogethers: val })}>
                    <Radio value="I'd prefer not to">I&apos;d prefer not to</Radio>
                    <Radio value="Once in a while">Once in a while</Radio>
                    <Radio value="As often as possible">As often as possible</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-0">
          Would you like to <span className="text-decoration-underline">hangout</span> together?
        </FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="hangoutTogether"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, hangoutTogether: val })}>
                    <Radio value="I'd prefer not to">I&apos;d prefer not to</Radio>
                    <Radio value="Once in a while">Once in a while</Radio>
                    <Radio value="As often as possible">As often as possible</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
      </Form>
    </div>
  );
};

export default Step8Form;
