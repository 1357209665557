import Image from 'next/image';
import React from 'react';

const MaintenanceComponent = () => {
  return (
    <>
      <div className=" flex justify-center flex-col text-center max-w-xl m-auto">
        <div className=" h-52 lg:h-80  flex justify-center m-10">
          <Image
            src="/static/images/website-maintenance.svg"
            className="h-full"
            alt="website under maintenance"
            layout="fill"
          />
        </div>
        <p className="text-4xl  font-bold my-4">Website under maintenance</p>
        <p className="my-4 text-base ">
          Our website is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your
          patience.
        </p>
      </div>
    </>
  );
};

export default MaintenanceComponent;
