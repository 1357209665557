import React from 'react';
import { GptHistoryResponse } from 'src/types/Services/GPT/gpt';
import { getDateAndTime } from 'utils/LeniQ/getDateAndTime';
interface LeniQHistorySectionProps {
  title: string;
  pastSearches: GptHistoryResponse[];
  handleQuestionClick: (question: string) => void;
}

const LeniQHistorySection: React.FC<LeniQHistorySectionProps> = ({ title, pastSearches, handleQuestionClick }) => {
  return (
    <div>
      <h4 className="text-gray-400 text-md mb-2">{title}</h4>
      <div className="flex flex-col border-[1px] border-b-[0px] border-gray-200 rounded-md">
        {pastSearches.map(search => {
          const dateTime = getDateAndTime(search.createdAt);
          return (
            <div
              key={search.id}
              className="px-3 py-2 border-b-[1px] border-gray-200 cursor-pointer"
              onClick={() => handleQuestionClick(search.question)}>
              <div className="flex items-center justify-between text-gray-400 text-xs mb-1">
                <span>{dateTime.formattedDate}</span>
                <span>{dateTime.formattedTime}</span>
              </div>
              <p className="text-sm">{search.question}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeniQHistorySection;
